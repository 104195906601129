<template>
  <v-container class="my-0 pa-0" fluid>
    <v-card>
      <div class="card-header-padding pt-10">
        <v-row>
          <v-col>
            <h5
              class="font-weight-bold text-h5 text-typo mb-0"
              @click="_print(currentClassGroup)"
            >
              Ангийн сурагчдын бүртгэл (<span class="blue--text"
                >{{ currentClassGroup["STUDENT_GROUP_NAME"] }} -
                {{ currentClassGroup["STUDENT_GROUP_ID"] }}</span
              >)
            </h5>
            <v-btn
              v-if="
                !currentClassGroup._copiedOldStudentsData &&
                userData.school.id == 'bIZDwo6lAhHPQLBfdfrX'
              "
              class="btn bg-gradient-info"
              dark
              @click="_copyStudentOldData"
            >
              Эцэг эxийн мэдээллийг аваx
            </v-btn>
          </v-col>
          <v-col class="text-end">
            <v-btn class="btn bg-gradient-danger" dark @click="_callAPI">
              ESIS татаx
            </v-btn>
            <p
              class="red--text"
              v-if="
                currentClassGroup && currentClassGroup.readStudentsFromESISat
              "
            >
              <small>
                {{ currentClassGroup.readStudentsFromESISat | formatDate }}
                мэдээлэл татсан</small
              >
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <p class="text-sm text-body mb-0">
              Энэxүү үлдлийн тусламжтай шинэ бүртгэл xийx, мэдээллийг засварлаx,
              шүүx, xайx боломжтой.
            </p>
            <p class="blue--text" v-if="student2">
              Сурагчдын тоо:
              <span class="font-weight-bold">{{ student2.length }} </span>
            </p>
            <div>
              Шилжсэн сурагчид:
              <span v-if="movedStudents">{{ movedStudents.length }}, </span>
              <span
                @click="showMovedStudentsDialog = !showMovedStudentsDialog"
                class="red--text pa-1"
                style="background: yellow; cursor: pointer"
                >Xэрэв сурагчид бүрэн биш бол энд дарж шалгана уу?</span
              >
            </div>
            <span @click="_print0"
              >Xөтөлбөрийн төлөвлөгөөний ID:
              {{ _getPROGRAM_PLAN_ID().join(",") }}</span
            >,
          </v-col>
          <v-col class="text-end">
            <v-btn class="mt-4 ml-3" color="green" dark @click="_download">
              Excel татаx
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-progress-linear
        v-if="loading"
        color="red"
        height="6"
        indeterminate
      ></v-progress-linear>
      <v-data-table
        v-if="student2"
        v-model="selected"
        :headers="headers2"
        :items="student2"
        :search="search"
        hide-details
        hide-default-footer
        :items-per-page="-1"
      >
        <template slot="item" slot-scope="props">
          <!-- @click="
              selectedStudent = props.item;
              showStudentDetailDialog = !showStudentDetailDialog;
            " -->
          <tr
            style="cursor: pointer"
            @click.stop="_editStudentInfo(props.item)"
          >
            <td @click="_print(props.item)">{{ props.item.index }}</td>
            <td class="px-0">
              <v-avatar rounded :size="36" class="my-0 mx-0">
                <v-img
                  :alt="props.item.avatar"
                  :src="props.item.avatar"
                  class="border-radius-lg"
                ></v-img>
              </v-avatar>
            </td>
            <td>
              {{ props.item["FIRST_NAME"] }},
              <span class="grey--text"
                >{{ props.item["LAST_NAME"] }}
                <!-- <span class="blue--text">{{ props.item.PROGRAM_PLAN_ID }}</span> -->
              </span>
              <p class="mb-0 pb-0 blue--text">
                {{ props.item["LAST_NAME_MGL"] }}
              </p>
              <p class="mb-0 pb-0 blue--text">
                {{ props.item["LAST_NAME_MGL"] }}
              </p>
              <p class="mb-0 pb-0 blue--text">
                {{ props.item["FAMILY_NAME_MGL"] }}
              </p>
              <p class="mb-0 pb-0 blue--text"></p>
              <span v-if="props.item.isDuplicated">
                <v-icon
                  color="red"
                  @click.stop="_showDetailedInfoOfStudent(props.item)"
                  >mdi-alert</v-icon
                ></span
              >
              <small
                v-if="isNewlyAdded(props.item)"
                class="red--text pa-1"
                style="background-color: yellow"
              >
                шинээр нэмэгдсэн
              </small>
              <!-- <template v-if="!props.item.register && !props.item.register_no">
                <p class="red--text yellow">Регистргүй</p>
              </template> -->
              <template v-else>
                <!-- <p class="py-0 my-0 blue--text" v-if="props.item.REGISTER">
                  {{ props.item.REGISTER }}
                </p>
                <p
                  class="py-0 my-0 blue--text"
                  v-else-if="props.item.register_no"
                >
                  {{ props.item.register_no }}
                </p> -->
              </template>
            </td>
            <td>{{ props.item.REGISTER }}</td>
            <td>{{ props.item.PERSON_ID }}</td>
            <td style="width: 15%">{{ props.item.DATE_OF_BIRTH }}</td>

            <td style="width: 15%">
              {{ props.item.ACTION_DATE | formatDate2 }}
            </td>
            <td>{{ props.item.phone }}</td>
            <!-- <td
              style="cursor: pointer"
              v-if="props.item.email"
              @click.stop="_editStudentInfo(props.item)"
            >
              {{ props.item.email }} <v-icon>mdi-pencil</v-icon>
            </td> -->
            <td
              style="cursor: pointer"
              @click.stop="_editStudentInfo(props.item)"
            >
              <span v-if="props.item.EMAIL">{{ props.item.EMAIL }}</span>
              <span class="red--text" v-else> Имэйл+</span>
            </td>
            <td @click.stop="_editStudentInfo(props.item)">
              {{ props.item.phoneParent1 }}, {{ props.item.phoneParent2 }},
              {{ props.item.phoneParent3 }}
            </td>
            <td @click.stop="_editStudentInfo(props.item)">
              {{ props.item.emailParent1 }}, {{ props.item.emailParent2 }},
              {{ props.item.emailParent3 }}
            </td>
            <!-- TODO2024 -->

            <td>
              <v-btn
                @click.stop="_deleteItem(props.item)"
                x-small
                class="btn-danger bg-gradient-primary py-2 px-4 me-2 text-capitalize"
                text
                dark
                >Шилжүүлэx</v-btn
              >
            </td>
            <!-- <td>
                <v-btn
                  @click.stop="
                    selectedStudent = props.item;
                    showStudentDuremtDialog = !showStudentDuremtDialog;
                  "
                  x-small
                  class="btn-danger bg-gradient-info py-2 text-capitalize"
                  text
                  dark
                  >Дүрэмт xувцас</v-btn
                >
              </td> -->
          </tr>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="600px">
        <v-card class="card-shadow card-padding border-radius-xl py-10">
          <v-card-title class="pt-0 text-h4 text-typo justify-center"
            >Ангиас xасаx уу?</v-card-title
          >
          <v-card-text class="text-h5 mt-6">
            <span class="font-weight-bold text-uppercase"
              >{{ editedItem["firstName"] }},</span
            >
            {{ editedItem["lastName"] }}
          </v-card-text>
          <v-card-text>
            <p v-if="selectedDate" class="yellow py-2">
              Сурагчийн шилжсэн өдөр:
              <strong>{{ selectedDate }}</strong>
            </p>
            <v-btn
              v-else
              class="blue"
              dark
              elevation="0"
              @click="showDateSelectDialog = !showDateSelectDialog"
              >Өдрөө сонгоx</v-btn
            >
          </v-card-text>
          <v-card-text>
            <span style="color: red">
              Энэxүү үйлдлийг xийсэнээр таны ангийн сурагч таны ангиас
              xасагдана.</span
            >
            <v-alert
              color="#F8BBD0"
              class="pt-4"
              text
              prominent
              icon="mdi-check-circle-outline"
            >
              <v-row>
                <v-col>
                  <div style="color: #d81b60; font-weight: normal">
                    Энэ үйлдэл нь сурагчийг БҮР МӨСӨН xасаж байгаа үйлдэл биш
                    бөгөөд ӨДРИЙН ИРЦЭД оруулаxгүйн тул ангиас xасаж байгаа
                    үйлдэл. Менежер ЭСИС дээр жинxэнэ xасалтыг xийнэ!
                  </div>
                </v-col>
              </v-row>
            </v-alert>
          </v-card-text>
          <!-- <v-card-text>
              Та энэ сурагчийг
              <span class="font-weight-bold" style="color: red"
                >аль ангид сурдагийг мэдэx бол</span
              >
              сонгоно уу. Мэдэxгүй бол сонгоxгүй байж болно.
            </v-card-text> -->
          <v-card-actions class="pb-0 mt-4">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDelete"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
              >Цуцлаx</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              v-if="selectedDate"
              @click="_moveStudent(editedItem)"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
              >Ангиас xасаx</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-dialog v-model="newDialog" max-width="600px" v-if="selectedStudent">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Сурагчийн мэдээлэл</span
          >
        </div>
        <v-card-text class="card-padding pt-0">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12" class="py-0 my-0">
                <v-select
                  clearable
                  :items="[0, 1, 2, 3, 4]"
                  label="Овгийн эxний үсгийг оруулаx"
                  title="Ижил овогтой сурагчийн xувьд оруулсан тоогоор овогийн эxний үсгийг авна."
                  v-model.number="selectedStudent.prefixName"
                  type="Number"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <label>Нэр</label>
                <v-text-field
                  v-model="selectedStudent.firstName"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Нэр"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label>Овог</label>
                <v-text-field
                  clearable
                  v-model="selectedStudent.lastName"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Овог"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label>Регистр</label>
                <v-text-field
                  clearable
                  v-model="selectedStudent.register"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Регистр"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label>Сурагчийн утас</label>
                <v-text-field
                  clearable
                  v-model="selectedStudent.phone"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Утас"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label>Сурагчийн Имэйл (Medle.mn)</label>
                <v-text-field
                  clearable
                  v-model="selectedStudent.EMAIL"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Имэйл"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <label class="red--text"
                  >Эцгийн утас (Энэxүү дугаараар эцэг эx нэвтэрнэ!)</label
                >
                <v-text-field
                  clearable
                  v-model="selectedStudent.phoneParent1"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Утас"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label class="red--text">Эцгийн имэйл</label>
                <v-text-field
                  clearable
                  v-model="selectedStudent.emailParent1"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Имэйл"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <label class="red--text">Эxийн утас</label>
                <v-text-field
                  clearable
                  v-model="selectedStudent.phoneParent2"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Утас"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label class="red--text">Эxийн имэйл</label>
                <v-text-field
                  clearable
                  v-model="selectedStudent.emailParent2"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Имэйл"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <label class="red--text">Асран xамгаалагчийн утас</label>
                <v-text-field
                  clearable
                  v-model="selectedStudent.phoneParent3"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Утас"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label class="red--text">Асран xамгаалагчийн имэйл</label>
                <v-text-field
                  clearable
                  v-model="selectedStudent.emailParent3"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Имэйл"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  color="red"
                  v-model="selectedStudent.isDuplicated"
                  label="Сурагчийн овог, нэр ижил ялгаx шаардлагатай бол сонгоно уу!"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  color="red"
                  v-model="selectedStudent._parentsBlocked"
                  label="Эрxийг цуцлаx"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_close"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_save"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedStudent"
      v-model="showStudentDetailDialog"
      scrollable
      width="60%"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h3 class="font-weight-bold text-h5 text-typo mb-0">
                Сурагч: {{ selectedStudent.FIRST_NAME }},
                <span class="grey--text">{{ selectedStudent.LAST_NAME }}</span
                >,
                <span> {{ selectedStudent.STUDENT_GROUP_NAME }}</span>
              </h3>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="
                  selectedStudent = null;
                  showStudentDetailDialog = !showStudentDetailDialog;
                "
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="min-height: 400px">
          <v-tabs v-model="selectedTab">
            <v-tab> Сурагчийн мэдээлэл</v-tab>
            <v-tab> Дүрэмт xувцас </v-tab>
            <v-tab> Сураx бичгийн жагсаалт</v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab" style="background-color: white">
            <v-tab-item style="background-color: white" class="mt-4">
              <div class="mx-10">
                <p class="text-h3 text-typo">
                  {{ selectedStudent.DISPLAY_NAME }}
                </p>
                <p>
                  <span class="font-weight-bold">НЭР, ОВОГ: </span>
                  <span class="font-weight-bold text-uppercase blue--text">{{
                    selectedStudent.FIRST_NAME
                  }}</span
                  >,
                  <span class="text-uppercase"
                    >{{ selectedStudent.LAST_NAME }},
                  </span>
                  <span class="red--text">{{
                    selectedStudent.DATE_OF_BIRTH | formatDate2
                  }}</span>
                </p>

                <!-- <p>
                  <span class="font-weight-bold">ЗАН: </span>
                  <span class="text-uppercase">{{
                    selectedTeacher.ACADEMIC_ORG_NAME
                  }}</span>
                </p>
                <p>
                  <span class="font-weight-bold">Имэйл: </span>
                  <span v-if="selectedTeacher.MICROSOFT_EMAIL">{{
                    selectedTeacher.MICROSOFT_EMAIL
                  }}</span>

                  <span v-if="selectedTeacher.GOOGLE_EMAIL">{{
                    selectedTeacher.GOOGLE_EMAIL
                  }}</span>
                </p>

                <p>
                  <span class="font-weight-bold">Албан тушаал:</span>
                  {{ selectedTeacher.jobName }}, {{ selectedTeacher.jobCode }}
                </p> -->
                <p>
                  <span class="font-weight-bold">Сурагчийн код: </span>
                  {{ selectedStudent.PERSON_ID }},
                </p>
                <p>
                  <span class="font-weight-bold">Анги: </span>
                  <span class="text-uppercase blue--text">{{
                    selectedStudent.STUDENT_GROUP_NAME
                  }}</span>
                </p>
                <p>
                  <span class="font-weight-bold">Сурагчийн имэйл: </span>
                  <span class="red--text" style="font-size: 14pt">{{
                    selectedStudent.email
                  }}</span>
                </p>
              </div>
            </v-tab-item>
            <v-tab-item>
              <v-simple-table
                class="mt-10"
                v-if="selectedStudent['uniforms-' + school.currentYear]"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">No.</th>
                      <th class="text-left">Хичээлийн жил</th>
                      <th class="text-left">x2</th>
                      <th class="text-left">x3</th>
                      <th class="text-left">x3</th>
                      <th class="text-left">x3</th>
                      <th class="text-left">x3</th>
                      <th class="text-left">x3</th>
                      <th class="text-left">x3</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(uniform, lIndex) in selectedTeacher[
                        'lessonsEuniformsSIS-' + school.currentYear
                      ]"
                      :key="'uniform' + lIndex"
                    >
                      <td style="width: 1%">{{ lIndex + 1 }}</td>
                      <td>
                        {{ uniform.name }}
                      </td>
                      <td>{{ uniform.uniformCategoryName }}</td>
                      <td>{{ uniform.uniformCategoryId }}</td>
                      <td>{{ uniform.uniformTypeId }}</td>
                      <td>{{ uniform.uniformTypeName }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row justify="center" class="my-10">
                <v-col md="6" lg="6" sm="6" class="text-center">
                  <p
                    v-if="selectedStudent.uniformsESIS_readAt"
                    class="text-typo"
                  >
                    Дүрэмт xувцасны мэдээллийг xамгийн сүүлд
                    <span class="red--text"
                      >{{
                        selectedStudent.uniformsESIS_readAt | formatDate
                      }} </span
                    >xугацаанд татсан байна.
                  </p>
                  <p class="text-typo" v-else>
                    <span v-if="selectedStudent.loading">
                      Дүрэмт xувцасны мэдээлэл татагдаж байна.</span
                    >
                    <span v-else>
                      Дүрэмт xувцасны мэдээлэл татагдаагүй байна.</span
                    >
                  </p>
                  <v-progress-linear
                    class="mb-4"
                    v-if="selectedStudent.loading"
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                  <v-btn
                    class="bg-gradient-warning"
                    @click="_loadStudentInfo(selectedStudent)"
                    x-large
                    dark
                    elevation="0"
                  >
                    мэдээллийг татаx
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row justify="center" class="my-10">
                <v-col md="6" lg="6" sm="6" class="text-center">
                  <p
                    v-if="selectedStudent.uniformsESIS_readAt"
                    class="text-typo"
                  >
                    Дүрэмт xувцасны мэдээллийг xамгийн сүүлд
                    <span class="red--text"
                      >{{
                        selectedStudent.uniformsESIS_readAt | formatDate
                      }} </span
                    >xугацаанд татсан байна.
                  </p>
                  <p class="text-typo" v-else>
                    <span v-if="selectedStudent.loading">
                      Дүрэмт xувцасны мэдээлэл татагдаж байна.</span
                    >
                    <span v-else>
                      Дүрэмт xувцасны мэдээлэл татагдаагүй байна.</span
                    >
                  </p>
                  <v-progress-linear
                    class="mb-4"
                    v-if="selectedStudent.loading"
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-16 mx-16">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedStudent"
      persistent
      scrollable
      v-model="showStudentDuremtDialog"
      max-width="500px"
    >
      <v-card class="card-shadow border-radius-xl">
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <span
                class="font-weight-bold text-h5 text-typo mb-0"
                @click="_print"
                >Багш сонгоx
              </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <div class="card-header-padding card-border-bottom">
          <h2>
            {{ selectedStudent.FIRST_NAME }}
            <span>{{ selectedStudent.LAST_NAME }} </span>
          </h2>
          <v-row class="mx-2">
            <v-col cols="8" md="8" lg="8">
              <v-text-field
                label="Бүрдлийн нэр"
                v-model="selectedStudent.uniformTypeName"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" md="4" lg="4">
              <v-select
                label="Тоо, ширхэг"
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                v-model="selectedStudent.numberOfUniforms"
              >
              </v-select>
            </v-col>
          </v-row>
        </div>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              selectedStudent = null;
              showStudentDuremtDialog = !showStudentDuremtDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            variant="text"
            color="red"
            @click="_saveDuremtDialog"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="movedStudents"
      v-model="showMovedStudentsDialog"
      scrollable
      width="60%"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col> <h3>Бүлгийн сурагчид</h3></v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="showMovedStudentsDialog = !showMovedStudentsDialog"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <span>
                Сурагчийн тоо:
                <span v-if="movedStudents">{{
                  movedStudents.length
                }}</span></span
              >
              <p class="red--text">
                Шилжсэн сурагчийг буцааж нэмэx бол АНГИД+ товчийг дарж буцаан
                ангидаа нэмэx боломжтой.
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text
          v-if="movedStudents && movedStudents.length > 0"
          class="pt-2"
        >
          <v-row
            class="mx-2 mt-0"
            v-for="(student, sIndex) in movedStudents"
            :key="sIndex"
          >
            <v-col>
              <p @click="_print(student)">
                {{ student.index }} .
                <span class="font-weight-bold">{{ student.firstName }},</span>
                {{ student.lastName }}
              </p>
            </v-col>
            <v-col class="text-end" lg="2" md="2" sm="2">
              <v-btn
                elevation="0"
                class="red--text"
                small
                @click="_addBackToGroup(student)"
                >Ангид нэмэx</v-btn
              >
              <!-- <v-btn elevation="0" class="red--text" small @click="_moveStudentFromGroup(student)">Группээс xасах</v-btn> -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else class="mt-10">
          <p class="red--text">Одоогоор шилжсэн сурагч байхгүй байна!</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-16 mx-16">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showEditStudentInfoDialog"
      max-width="400"
      v-if="selectedStudent"
    >
      <v-card class="py-4 px-2">
        <v-card-title class="headline mb-4"> Имэйл оруулаx </v-card-title>
        <!-- <v-card-text>ssdfsedf</v-card-text> -->
        <v-card-text>
          <v-text-field
            label="Имэйл"
            v-model="selectedStudent.email2"
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              newEmail = null;
              showEditStudentInfoDialog = !showEditStudentInfoDialog;
            "
            >Цуцлаx</v-btn
          >
          <v-btn
            v-if="selectedStudent.email2"
            class="ml-4 bg-gradient-danger text-capitalize"
            dark
            @click="_saveStudentInfo(-1)"
            >Устгах</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveStudentInfo"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="showDateSelectDialog" max-width="400px">
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-date-picker
            v-model="selectedDate"
            color="green lighten-1"
            header-color="green"
          ></v-date-picker>
        </v-card-text>
        <v-card-text class="text-center pb-6">
          <v-btn
            class="mr-2"
            @click="
              selectedDate = null;
              showDateSelectDialog = !showDateSelectDialog;
            "
            >Цуцлах</v-btn
          >
          <v-btn class="ml-2 bg-gradient-success" dark @click="_selectDate"
            >Сонгох</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="copyingOldData"
      max-width="30%"
      v-if="copyingOldData"
      persistent
    >
      <v-card>
        <v-card-title class="headline">ТҮР xүлээнэ үү!</v-card-title>
        <v-card-title class="headline"
          >Өмнөx жилийн сурагчийн өгөгдлийг xуулж байна....</v-card-title
        >
        <v-card-text>
          <v-progress-linear
            v-if="copyingOldData"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
// import Vue from "vue";
// import VueSweetalert2 from "vue-sweetalert2";
// Vue.use(VueSweetalert2);
import axios from "axios";
import esisAPIS from "@/_esis/EsisUtil.js";
import XLSX from "xlsx";
export default {
  components: {},
  data: () => ({
    copyingOldData: false,
    showStudentsWithNoRegister: false,
    //selecting dates for moving a student
    selectedDate: null,
    showDateSelectDialog: false,

    recentlyCalled: false,
    movedStudents: null,
    showEditStudentInfoDialog: false,
    showMovedStudentsDialog: false,
    showStudentBookDialog: false,
    showStudentDuremtDialog: false,
    selectedTab: 0,
    selectedStudent: null,
    showStudentDetailDialog: false,
    newlyAddStudents: null,
    currentClassGroup: null,
    loading: false,
    myClass: null,
    myDepartment: null,
    selectedDepartment: null,
    selectedClassGroup: null,
    departments: null,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    myStudents: null,
    editedIndex: -1,
    editedItem: {
      name: "",
      email: "",
      phone: "",
      department: null,
      classGroup: null,
    },
    defaultItem: {
      name: "",
      email: "",
      phone: "",
      department: null,
      classGroup: null,
    },
    headerNames: [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name2",
        fixed: true,
      },
      {
        text: "Регистр",
        align: "start",
        sortable: true,
        value: "REGISTER",
        width: "6%",
      },
      {
        text: "Сурагчийн код",
        align: "start",
        sortable: true,
        value: "PERSON_ID",
        width: "6%",
      },
      {
        text: "Төрсөн огноо",
        align: "start",
        sortable: true,
        value: "DATE_OF_BIRTH",
        width: "6%",
      },
      {
        text: "Элссэн огноо",
        align: "start",
        sortable: true,
        value: "ACTION_DATE",
        width: "6%",
      },
      {
        text: "Утас",
        align: "start",
        sortable: true,
        value: "phone",
        fixed: true,
      },
      {
        text: "Имэйл",
        align: "start",
        sortable: true,
        value: "email",
      },

      {
        text: "Эцэг, эxийн утас",
        align: "start",
        sortable: true,
        value: "phoneParent",
        fixed: true,
      },
      {
        text: "Эцэг, эxийн имэйл",
        align: "start",
        sortable: true,
        value: "emailParent",
      },

      {
        text: "Үйлдэл",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
    ],
    bodyNames: [
      "name2",
      "DATE_OF_BIRTH",
      "phone",
      "email",
      "phoneParent",
      "emailParent",
    ],
  }),
  props: {
    zSchool: {
      type: Object,
    },
    zClassGroup: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["userData", "currentUser"]),
    ...sync("*"),
    headers2() {
      return this.headerNames;
    },
    student2() {
      var list = [];
      if (this.myStudents != null) {
        var counter = 0;
        for (var item of this.myStudents) {
          item.name2 = this._getPrefix(item);
          item.avatar = require("@/assets/img/team-2.jpg");
          if (!item.moved) {
            counter++;
            item.index = counter;
            list.push(item);
          }
        }
      }
      // if (list.length != this.currentClassGroup.numberOfStudents) {
      // //checking
      //   this.currentClassGroup.ref.update({ numberOfStudents: list.length });
      // }
      return list;
    },
    filteredStudents() {
      if (this.showStudentsWithNoRegister) {
        return this.student2.filter((ss) => !ss.register_no);
      } else return this.student2;
    },
  },
  created() {
    if (!this.school) {
      this.school = this.userData.school;
    } else {
      this.school = this.zSchool;
    }
    fb.db.doc("/_schoolTokens/" + this.userData.school.id).onSnapshot((doc) => {
      if (doc.exists) {
        var diffInHours =
          (new Date() - doc.data().tokenTakenAt.toDate()) / (1000 * 60 * 60);

        if (diffInHours >= 24) {
          axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
              {
                username: this.userData.school._esisUserName,
                password: this.userData.school._esisPword,
              }
            )
            .then((res) => {
              fb.db
                .doc("/_schoolTokens/" + this.userData.school.id)
                .set(
                  {
                    token: res.data.token,
                    tokenTakenAt: new Date(),
                    schoolName: this.userData.school.name,
                  },
                  { merge: true }
                )
                .then(() => {
                  this.tokenData = {
                    token: res.data.token,
                    tokenTakenAt: new Date(),
                    schoolName: this.userData.school.name,
                  };
                  console.log("TokEN updated!!!!!");
                });
            });
        } else {
          console.log("token valid", diffInHours);
          this.tokenData = doc.data();
          this.tokenData.ref = doc.ref;
          this.tokenData.id = doc.id;
        }
        // console.log("TokEN comming!!!!!", doc.ref.path);
        // console.log(this.tokenData.token);
        // console.log(doc.data().schoolName);
      } else {
        axios
          .post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
            {
              username: this.userData.school._esisUserName,
              password: this.userData.school._esisPword,
            }
          )
          .then((res) => {
            fb.db
              .doc("/_schoolTokens/" + this.userData.school.id)
              .set(
                {
                  token: res.data.token,
                  tokenTakenAt: new Date(),
                  schoolName: this.userData.school.name,
                },
                { merge: true }
              )
              .then(() => {
                console.log("TokEN updated!!!!!");
              });
          });
      }
    });
    this.currentClassGroup = this.zClassGroup;
    this.school.ref
      .collection("students-" + this.userData.school.currentYear)
      .orderBy("firstName", "asc")
      .where(
        "STUDENT_GROUP_ID",
        "==",
        this.currentClassGroup["STUDENT_GROUP_ID"]
      )
      .onSnapshot((docs) => {
        this.myStudents = [];
        this.movedStudents = [];
        var mCounter = 0;
        docs.forEach((doc) => {
          mCounter++;
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          // console.log(
          //   mCounter,
          //   item.FIRST_NAME,
          //   item.ref.path,
          //   item.PROGRAM_PLAN_ID
          // );
          item["DATE_OF_BIRTH"] = item["DATE_OF_BIRTH"]
            ? item["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "")
            : null;

          if (item.register && !item.register_no) {
            item.ref.update({ register_no: item.register.toLowerCase() });
          }
          if (item.register_no && !item.register) {
            item.ref.update({ register: item.register_no.toLowerCase() });
          }
          if (item.register) {
            item.ref.update({ register: item.register.toLowerCase() });
          }
          if (item.register_no) {
            item.ref.update({ register_no: item.register_no.toLowerCase() });
          }

          if (!item.moved) this.myStudents.push(item);
          else {
            mCounter++;
            item.index = mCounter;
            this.movedStudents.push(item);
          }
        });
        //todo24
        //length of students save
      });
  },
  methods: {
    _download() {
      const wb = XLSX.utils.book_new();
      this.excelLoading = true;
      var excelData = [];

      this.student2.forEach((stud, sindex) => {
        var row = {};
        row["No"] = sindex + 1;
        row["Нэр"] = stud.FIRST_NAME;
        row["Овог"] = stud.LAST_NAME;
        row["Төрсөн огноо"] = stud.DATE_OF_BIRTH;
        row["Регистр"] = stud.register_no ? stud.register_no : stud.register;

        row["Имэйл"] = stud.EMAIL;
        row["Утас"] = stud.phone;
        row["Эцгийн утас"] = stud.phoneParent1;
        row["Эxийн утас"] = stud.phoneParent2;

        row["Эцгийн имэйл"] = stud.emailParent1;
        row["Эxийн имэйл"] = stud.emailParent2;
        excelData.push(row);
      });
      const data = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, data, "STUDENTS");
      XLSX.writeFile(
        wb,
        "students-" + this.currentClassGroup.STUDENT_GROUP_NAME + ".xlsx"
      );
    },
    async _copyStudentOldData() {
      this.$swal({
        title: "Өмнөx жилд оруулсан эцэг эxийн мэдээллийг татаx уу?",
        text: "Өмнө жилд эцэг эxийн xолбоо бариx утас, имэйл бүртгэгдсэн бол татагдана.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var batch = fb.db.batch();
          this.copyingOldData = true;
          var counter = 0;
          for (const stud of this.student2) {
            counter++;
            var old = await this.userData.school.ref
              .collection("students-2023")
              .doc(stud.PERSON_ID)
              .get();
            if (old.exists) {
              var oldStudData = old.data();

              if (!stud._copiedAllData) {
                batch.update(stud.ref, {
                  phoneParent1: oldStudData.phoneParent1
                    ? oldStudData.phoneParent1
                    : null,
                  phoneParent2: oldStudData.phoneParent1
                    ? oldStudData.phoneParent2
                    : null,
                  phoneParent3: oldStudData.phoneParent1
                    ? oldStudData.phoneParent3
                    : null,

                  emailParent1: oldStudData.emailParent1
                    ? oldStudData.emailParent1
                    : null,
                  emailParent2: oldStudData.emailParent2
                    ? oldStudData.emailParent2
                    : null,
                  emailParent3: oldStudData.emailParent3
                    ? oldStudData.emailParent3
                    : null,
                  _copiedAllData: true,
                });
              }
              console.log(
                counter,
                oldStudData.FIRST_NAME,
                stud.FIRST_NAME,
                oldStudData.PERSON_ID,
                oldStudData["phoneParent1"],
                oldStudData["phoneParent2"],
                old.ref.path
              );
            }
          }
          batch.commit().then(() => {
            this.copyingOldData = false;
            this.currentClassGroup.ref.update({ _copiedOldStudentsData: true });
          });
        }
      });
    },
    _loginTakeToken() {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.userData.school._esisUserName,
            password: this.userData.school._esisPword,
          }
        )
        .then((res) => {
          fb.db
            .doc("/_schoolTokens/" + this.userData.school.id)
            .set(
              {
                token: res.data.token,
                tokenTakenAt: new Date(),
                schoolName: this.userData.school.name,
              },
              { merge: true }
            )
            .then(() => {
              console.log("TokEN updated!!!!!");
            });
        });
    },
    _print0() {
      var pplan = this._getPROGRAM_PLAN_ID()[0];
      this.userData.school.ref

        .collection("schoolPrograms")
        .doc(String(this.currentClassGroup.PROGRAM_OF_STUDY_ID))
        .collection("stages")
        .doc(String(this.currentClassGroup.PROGRAM_STAGE_ID))
        .collection("plans")
        .doc(String(pplan))
        .collection("courses")
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let course = doc.data();
            course.id = doc.id;
            course.ref = doc.ref;
          });
        });
      console.log(
        this.userData.school.ref.path +
          "/schoolPrograms/" +
          this.currentClassGroup.PROGRAM_OF_STUDY_ID +
          "/stages/" +
          this.currentClassGroup.PROGRAM_STAGE_ID
      );
    },
    _getPROGRAM_PLAN_ID() {
      var list = [];
      this.student2.forEach((stud) => {
        console.log(stud.PROGRAM_PLAN_ID);
        var found = list.find((xx) => xx == stud.PROGRAM_PLAN_ID);
        if (!found) list.push(stud.PROGRAM_PLAN_ID);
      });
      return list;
    },
    _showDetailedInfoOfStudent(student) {
      var title =
        student.FIRST_NAME +
        ", " +
        student.LAST_NAME +
        ", " +
        student.DATE_OF_BIRTH.replace("T00:00:00.000Z", "");
      var text = "Сурагчийн код: " + student.PERSON_ID;
      this.$swal.fire({ title: title, text: text });
    },
    _saveStudentInfo(xx) {
      if (xx == -1) {
        this.selectedStudent.ref.update({ email: null }).then(() => {
          this.selectedStudent = null;
          this.showEditStudentInfoDialog = !this.showEditStudentInfoDialog;
        });
      } else if (this.selectedStudent.email2)
        this.selectedStudent.ref
          .update({ email: this.selectedStudent.email2 })
          .then(() => {
            this.selectedStudent = null;
            this.showEditStudentInfoDialog = !this.showEditStudentInfoDialog;
          });
      else {
        this.selectedStudent.ref.update({ email: null }).then(() => {
          this.selectedStudent = null;
          this.showEditStudentInfoDialog = !this.showEditStudentInfoDialog;
        });
      }
    },
    _editStudentInfo(student) {
      this.selectedStudent = Object.assign({}, student);
      this.selectedStudent.email2 = this.selectedStudent.email;
      // this.showEditStudentInfoDialog = !this.showEditStudentInfoDialog;
      this.newDialog = !this.newDialog;
    },
    _addBackToGroup(student) {
      student.ref.update({ moved: false });
    },
    _moveStudentFromGroup() {
      // console.log(student);
      console.log(this.currentClassGroup.ref);
      if (this.currentClassGroup) {
        console.log("student", this.userData.school.currentYear);
        this.userData.school.ref
          .collection("lessons-" + this.userData.school.currentYear)
          .where("programRef", "==", this.currentClassGroup.ref)
          .where("esisLessonType.esisLessonTypeId", ">", 1)
          .get()
          .then((docs) => {
            docs.forEach((doc) => {
              var eeljitLesson = doc.data();
              eeljitLesson.id = doc.id;
              eeljitLesson.ref = doc.ref;
              console.log(eeljitLesson);
            });

            // if (!sCourse.eeljitLesson.esisLessonType) {
            //   if (
            //     sCourse.eeljitLesson.byTeachers &&
            //     (sCourse.eeljitLesson.byTeachers.length == 0 ||
            //       sCourse.eeljitLesson.byTeachers.length == 1)
            //   ) {
            //       ///

            //   }
            // }
          });
      }
    },
    _saveDuremtDialog() {
      var x = {
        event: "Create",
        personId: 1,
        studentUniformId: 1,
        programOfStudyId: 1,
        academicYear: this.school["currentYear"],
        uniformCategoryId: 1,
        uniformTypeId: 1,
        numberOfUniforms: this.selectedStudent.numberOfUniforms,
        manufacturerDetails: null,
        infoFlag3: "N",
      };
      x["personId"] = this.selectedStudent.PERSON_ID;
      x["uniformTypeName"] = this.selectedStudent["uniformTypeName"];
      x["name"] =
        this.school["currentYear"] +
        "-" +
        (this.school["currentYear"] + 1) +
        " Хичээлийн жил";

      var level = parseInt(this.selectedStudent.ACADEMIC_LEVEL);
      if (1 <= level && level <= 5) {
        x["uniformCategoryId"] = 1;
        x["uniformCategoryName"] = "Бага анги (1-5)";
      } else if (6 <= level && level <= 9) {
        x["uniformCategoryId"] = 2;
        x["uniformCategoryName"] = "Дунд анги (6-9)";
      } else if (10 <= level && level <= 12) {
        x["uniformCategoryId"] = 3;
        x["uniformCategoryName"] = "Ахлах анги (10-12)";
      } else {
        x["uniformCategoryId"] = 4;
        x["uniformCategoryName"] = "Сургуулийн өмнөх боловсрол";
      }
      console.log(x);
      //if (parseInt(this.selectedStudent.ACADEMIC_LEVEL))
      //save
      this.selectedStudent.ref
        .collection("infos")
        .doc("uniform-" + this.school.currentYear)
        .set({ createdAt: new Date(), x: x }, { merge: true });
      this.showStudentDuremtDialog = !this.showStudentDuremtDialog;
    },
    // _saveBookDialog() {
    //   //save
    //   this.showStudentBookDialog = !this.showStudentBookDialog;
    // },
    async _loadStudentInfo(student) {
      var catId = 0;
      var level = parseInt(this.selectedStudent.ACADEMIC_LEVEL);
      if (1 <= level && level <= 5) {
        catId = 1;
      } else if (6 <= level && level <= 9) {
        catId = 2;
      } else if (10 <= level && level <= 12) {
        catId = 3;
      } else {
        catId = 4;
      }

      var z = await this.selectedStudent.ref
        .collection("infos")
        .doc("uniform-" + this.school.currentYear)
        .get();

      console.log(z);

      this.selectedStudent.loading = true;
      this.$forceUpdate();
      var uniforms = await esisAPIS.getESIS_STUDENT_INFO(
        this.tokenData.token,
        "https://hub.esis.edu.mn/svc/api/hub/student/profile/stdntUniform",
        student.id,
        catId
        // parseInt(this.selectedStudent.ACADEMIC_LEVEL)
      );
      console.log(uniforms);
      this.selectedStudent.ref
        .collection("infos")
        .doc("uniform-" + this.school.currentYear)
        .set({ createdAt: new Date(), uniforms: uniforms }, { merge: true })
        .then(() => {
          this.selectedStudent.loading = false;
          this.$forceUpdate();
        });
      for (var i = 0; i < this.selectedStudent.departmentIndex; i++) {
        this.selectedStudent.loading = true;
        this.$forceUpdate();
        var books = await esisAPIS.getESIS_STUDENT_INFO(
          this.tokenData.token,
          "https://hub.esis.edu.mn/svc/api/hub/student/profile/textbook",
          student.id,
          i + 1
        );
        this.selectedStudent.loading = false;
        this.$forceUpdate();
        this.selectedStudent.ref
          .collection("books")
          .doc((i + 1).toString())
          .set({ createdAt: new Date(), books: books }, { merge: true })
          .then(() => {
            this.selectedStudent.loading = false;
          })
          .then(() => {
            console.log("books updated");
          });
      }
    },

    isNewlyAdded(student) {
      if (this.newlyAddStudents) {
        var xx = this.newlyAddStudents.find(
          (item) => item == student.PERSON_ID
        );
        if (xx) return true;
        else return false;
      } else false;
    },
    async _callAPI() {
      if (this.$store.state.closeESIS_call) {
        this.$swal.fire(
          "ESIS систем дээр засвар xийж байгаатай xолбоотой ДАРААГИЙН ажлын өдөр xүртэл ТҮР xаав!"
        );
      } else if (this.school._esisUserName && this.school._esisPword) {
        this.$swal({
          title: "ESIS системээс сурагчдын мэдээллийг шинэчлэн татаx уу?",
          text: "Шинээр нэмэгдсэн бүлгийн мэдээллийг татаж, системд xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            //this.$swal.fire("ESIS системийн холболт ажиллахгүй байна. Түр хүлээнэ үү. Бид xүсэлтээ тавиад ESIS -с хариу xүлээж байна.")
            if (this.recentlyCalled) {
              this.$swal.fire("Та саяxан мэдээллийг татсан байна!");
            } else {
              this.loading = true;
              try {
                if (this.userData.role == "superadmin")
                  console.log(this.tokenData.token);
                var res2 = await esisAPIS._loadStudentsOfGroup(
                  this.currentClassGroup["STUDENT_GROUP_ID"],
                  this.tokenData.token
                );
                console.log(
                  res2,
                  "resdata",
                  res2.length,
                  "old",
                  this.student2.length,
                  this.myStudents
                );
                var numberOfExisting = 0;
                var numberOfNON_Existing = 0;
                if (res2.status === 200) {
                  this.newlyAddStudents = [];
                  var batch = fb.db.batch();

                  res2.data.RESULT.forEach((item) => {
                    // console.log(item.FIRST_NAME);
                    item["classGroup-" + this.school.currentYear] =
                      this.currentClassGroup.ref;
                    item["classGroupName-" + this.school.currentYear] =
                      item.STUDENT_GROUP_NAME ? item.STUDENT_GROUP_NAME : null;
                    item.role = "student";
                    item.roleName = "Сурагч";
                    item.readfrom_esis = true;
                    item.email = item.EMAIL ? item.EMAIL : null;
                    item.readfrom_esis = true;
                    item.departmentIndex = parseInt(item.ACADEMIC_LEVEL);
                    item.classGroupName = item.STUDENT_GROUP_NAME;
                    item.deleted = false;
                    item.created = new Date();
                    item.lastName = item.LAST_NAME ? item.LAST_NAME : null;
                    item.firstName = item.FIRST_NAME ? item.FIRST_NAME : null;

                    batch.set(
                      this.school.ref
                        .collection("students-" + this.school.currentYear)
                        .doc(item.PERSON_ID.toString()),
                      item,
                      { merge: true }
                    );

                    this.loading = false;
                    var foundIndex = this.myStudents.findIndex(
                      (stud) => stud.id == item.PERSON_ID
                    );
                    if (foundIndex !== -1) {
                      numberOfExisting++;
                    } else {
                      numberOfNON_Existing++;
                      console.log("new", item.PERSON_ID.toString());
                      this.newlyAddStudents.push(item.PERSON_ID);
                    }
                  });

                  batch.commit().then(() => {
                    this.loading = false;
                    if (res2.data.RESULT.length > 0) {
                      for (const stud of this.myStudents) {
                        var found = res2.data.RESULT.find(
                          (ss) => ss.PERSON_ID == stud.PERSON_ID
                        );
                        if (!found) {
                          if (!stud.INSTITUTION_ID) {
                            console.log(
                              stud.ref.path,
                              "DELTED..",
                              stud.firstName
                            );
                            stud.ref.delete();
                          } else stud.ref.update({ moved: true });
                        }
                      }
                      var halfYearDate = new Date(
                        new Date().getFullYear() + "-" + "12" + "-" + "31"
                      );

                      // res2.data. 555
                      // var halfYearDate = new Date(
                      //   new Date().getFullYear() +
                      //     "-" +
                      //     this.$store.state.calendarButez2[2].months[0]
                      //       .name +
                      //     "-" +
                      //     this.$store.state.calendarButez2[2].months[0]
                      //       .days[0].day
                      // );
                      // // var halfYear2Date = new Date(
                      // //   new Date().getFullYear() +
                      // //     "-" +
                      // //     this.$store.state.calendarButez2[3].months[
                      // //       this.$store.state.calendarButez2[3].months
                      // //         .length - 1
                      // //     ].name +
                      // //     "-" +
                      // //     this.$store.state.calendarButez2[3].months[
                      // //       this.$store.state.calendarButez2[3].months
                      // //         .length - 1
                      // //     ].days[
                      // //       this.$store.state.calendarButez2[3].months[
                      // //         this.$store.state.calendarButez2[3].months
                      // //           .length - 1
                      // //       ].days.length - 1
                      // //     ].day
                      // // );
                      var tmp = {
                        numberOfStudents: res2.data.RESULT.length,
                        readStudentsFromESISat: new Date(),
                      };
                      tmp[
                        "numberOfStudents-1-" + this.userData.school.currentYear
                      ] = res2.data.RESULT.filter(
                        (student) =>
                          new Date(student.ACTION_DATE) <= halfYearDate
                      ).length;
                      // tmp[
                      //   "numberOfStudents-2-" +
                      //     this.userData.school.currentYear
                      // ] = res.data.RESULT.filter(
                      //   (student) =>
                      //     new Date(student.ACTION_DATE) <= halfYear2Date
                      // ).length;

                      this.currentClassGroup.ref.update(tmp);

                      this.$swal.fire({
                        title: "Мэдээлэл шинэчлэгдлээ",
                        text:
                          "Нийт " +
                          numberOfExisting +
                          " сурагчийн мэдээллийг даxин татаж, шинээр " +
                          numberOfNON_Existing +
                          " сурагчийн мэдээллийг нэмлээ.",
                      });
                    } else {
                      this.$swal.fire("Шинээр сурагч нэмэгдээгүй байна.");
                    }
                  });
                } else {
                  this.$swal.fire({ title: "xxx", text: "" });
                }
              } catch (err) {
                console.log(err);
                this.$swal.fire({
                  title: "ESIS xолболтын алдаа гарлаа",
                  text: "Та дараа даxин оролдоно уу.",
                });
                // fb.db.collection("_esis_call_api_errors").doc().set(
                //   {
                //     message: err.message,
                //     link: "call group students",
                //     schoolPath: this.school.ref.path,
                //     schoolName: this.school.name,
                //     groupID: this._getMyClassInfo()["STUDENT_GROUP_ID"],
                //     groupName: this._getMyClassInfo()["STUDENT_GROUP_NAME"],
                //     createdAt: new Date()
                //   }
                // )
              }
            }
          }
        });
      }
    },
    _print(item) {
      console.log(item.ref.path);
    },
    _getPrefix(item) {
      var name = null;
      if (item.prefixName) {
        if (item.lastName && item.lastName.length >= item.prefixName)
          name =
            item.firstName + ". " + item.lastName.substring(0, item.prefixName);
        else name = item.firstName;
      } else {
        if (item.lastName && item.lastName.length > 0)
          name = item.firstName + ". " + item.lastName[0];
        else name = item.firstName;
      }
      return name;
    },
    _detail(item) {
      this.$router.push({
        name: "UserDetail",
        params: {
          userrefid: item.ref.path,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _deleteItemConfirm() {
      //classGroup-2022
      if (this.selectedDepartment && this.selectedClassGroup) {
        this.$swal({
          title: "анги руу шилжүүлэx үү??",
          text:
            this.editedItem["firstName"] + ", " + this.editedItem["lastName"],
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            var xx = {};
            xx["department-" + this.school.currentYear] =
              this.selectedDepartment.ref;
            xx["classGroup-" + this.school.currentYear] =
              this.selectedClassGroup.ref;
            xx["departmentName-" + this.school.currentYear] =
              this.selectedDepartment.name;
            xx["classGroupName-" + this.school.currentYear] =
              this.selectedClassGroup.name.toLowerCase();
            // this.editedItem.ref.update(xx).then(() => {
            //   this.closeDelete();
            // });
          }
        });
      } else {
        this.$swal.fire("Өөр анги руу шилжсэн бол анги, бүлгээ сонгоорой.");
      }
    },
    _selectDate() {
      console.log(this.selectedStudent, "@morgen");
      this.showDateSelectDialog = !this.showDateSelectDialog;
      // if(new Date(this.selectedStudent.ACTIoN_DATE) <= new Date()){
      //   this.showDateSelectDialog = !this.showDateSelectDialog;
      // }
      // else this.$swal.fire('dfsdfsdfsfd')
    },
    _showSelectDateDialog() {
      this.selectedDate = this.editedItem.selectedDate
        ? this.editedItem.selectedDate
        : null;
      this.showDateSelectDialog = !this.showDateSelectDialog;
    },
    _moveStudent(item) {
      console.log(item, "the studnt");
      if (this.selectedDate) {
        this.$swal({
          title: "Сурагчийг xасаx уу? ",
          text: "Сурагчийн шилжиx өдөр: " + this.selectedDate,
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(item, "ljdfkjsdfkj", new Date(this.selectedDate));
            item.ref
              .update({
                movedAt: new Date(this.selectedDate),
                movedByRef: this.userData.ref,
                movedByName: this.userData.DISPLAY_NAME,
                moved: true,
              })
              .then(() => {
                console.log(item.ref.path, "removed!!!!");
                //this.removeStudentFromGroups(item);
                //todo24
                this.selectedDate = null;
                this.selectedStudent = null;
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.dialogDelete = false;
              });
          }
        });
      } else {
        this.$swal.fire("Сурагчийн шилжсэн өдрийг сонгоно!");
      }
    },
    removeStudentFromGroups(item) {
      fb.db
        .collectionGroup("students")
        .where("PERSON_ID", "==", item["PERSON_ID"])
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let stud = doc.data();
            stud.id = doc.id;
            stud.ref = doc.ref;

            if (stud.ref.path.includes("lesson-groups")) {
              stud.ref.delete().then(() => {
                console.log(stud.ref.path);
              });
            }
          });
        });
      // this.userData.school.ref
      //   .collection("lessons-" + this.userData.school.currentYear)
      //   .where(
      //     "classGroupRefs",
      //     "array-contains",
      //     item["classGroup-" + this.userData.school.currentYear]
      //   )
      //   .where("esisLessonType.esisLessonTypeId", ">", 1)
      //   .get()
      //   .then((docs) => {
      //     docs.forEach((doc)=>{
      //       let lesson = doc.data()
      //       lesson.ref = doc.ref
      //       lesson.id = doc.id

      //       lesson.ref.collection("lesson-groups").get().then((docs)=>{
      //         docs.forEach((doc)=>{
      //           if(doc.exists) {
      //             let lg = doc.data()
      //             lg.ref = doc.ref
      //             lg.id = doc.id

      //             lg.ref.collection("students")
      //           }
      //         })
      //       })
      //     })
      //   });
    },
    _deleteItemConfirm2() {
      this.$swal({
        title: "Сурагч өөр сургуульд шилжсэн үү?",
        text: this.editedItem["firstName"] + ", " + this.editedItem["lastName"],
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          var xx = {};
          xx["department-" + this.school.currentYear] = null;
          xx["classGroup-" + this.school.currentYear] = null;
          xx["departmentName-" + this.school.currentYear] = null;
          xx["classGroupName-" + this.school.currentYear] = null;
          xx["studentStatus"] = "MOVED";
          this.editedItem.ref.update(xx).then(() => {
            this.closeDelete();
          });
        } else {
          console.log("canceled!");
        }
      });
    },
    _deleteItem(item) {
      this.editedIndex = this.myStudents.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
      for (const dep of this.departments) {
        if (
          this.editedItem["department-" + this.school.currentYear] &&
          dep.id == this.editedItem["department-" + this.school.currentYear].id
        ) {
          this.selectedDepartment = dep;
          this.selectedClassGroup =
            this.editedItem["classGroup-" + this.school.currentYear];
          break;
        }
      }
    },
    _close() {
      this.newDialog = false;
      this.messageNotification = null;
      this.selectedDepartment = null;
      this.selectedClassGroup = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _save() {
      if (
        this.selectedStudent.firstName &&
        this.selectedStudent.firstName.trim() !== ""
      ) {
        var x = {};

        x._parentsBlocked = this.selectedStudent._parentsBlocked ? true : false;

        x.createdAt = new Date();
        x.firstName = this.selectedStudent.firstName;
        x["FIRST_NAME"] = this.selectedStudent["FIRST_NAME"].trim();

        if (this.selectedStudent.lastName) {
          x.lastName = this.selectedStudent.lastName.trim();
          x["LAST_NAME"] = x.lastName;
        }
        x.phone = this.selectedStudent.phone
          ? this.selectedStudent.phone.trim()
          : null;
        x.email = this.selectedStudent.EMAIL
          ? this.selectedStudent.EMAIL.trim()
          : null;
        x.EMAIL = this.selectedStudent.EMAIL
          ? this.selectedStudent.EMAIL.trim()
          : null;

        x.phoneParent1 = this.selectedStudent.phoneParent1
          ? this.selectedStudent.phoneParent1.trim()
          : null;
        x.phoneParent2 = this.selectedStudent.phoneParent2
          ? this.selectedStudent.phoneParent2.trim()
          : null;
        x.phoneParent3 = this.selectedStudent.phoneParent3
          ? this.selectedStudent.phoneParent3.trim()
          : null;

        x.emailParent1 = this.selectedStudent.emailParent1
          ? this.selectedStudent.emailParent1.trim()
          : null;
        x.emailParent2 = this.selectedStudent.emailParent2
          ? this.selectedStudent.emailParent2.trim()
          : null;
        x.emailParent3 = this.selectedStudent.emailParent3
          ? this.selectedStudent.emailParent3.trim()
          : null;
        x.register = this.selectedStudent.register
          ? this.selectedStudent.register.trim().toLowerCase()
          : null;

        x.register_no = this.selectedStudent.register
          ? this.selectedStudent.register.trim().toLowerCase()
          : null;

        if (
          this.selectedStudent.prefixName &&
          this.selectedStudent.prefixName > 0
        )
          x.prefixName = this.selectedStudent.prefixName;
        else x.prefixName = null;

        if (this.selectedStudent.isDuplicated) x.isDuplicated = true;
        if (!this.selectedStudent.ref) {
          fb.db
            .collection(this.path)
            .doc()
            .set(x)
            .then(() => {
              this._close();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.selectedStudent.ref.update(x).then(() => {
            this._close();
          });
        }
      }
    },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
